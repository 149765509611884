<template>
  <div>
    <UserList ref="userList" />

    <BaseModal
      v-model="newUserModalActive"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="New user"
      aria-modal
    >
      <CardPopup
        title="New user"
        class="w-800"
        @hide="newUserModalActive = false"
      >
        <template #body>
          <b-field label="First Name">
            <b-input
              v-model="newUser.firstName"
              type="text"
              placeholder="e.g. John"
              required
            />
          </b-field>
          <b-field label="Last Name">
            <b-input
              v-model="newUser.lastName"
              type="text"
              placeholder="e.g. Doe"
              required
            />
          </b-field>
          <b-field label="Email">
            <b-input
              v-model="newUser.email"
              type="email"
              placeholder="e.g. john.doe@example.com"
              required
            />
          </b-field>
          <b-field label="Password">
            <b-input
              v-model="newUser.password"
              type="password"
              placeholder="e.g. john.doe@example.com"
              required
              password-reveal
            />
          </b-field>
          <b-field label="Role">
            <b-select
              v-model="newUser.role"
              placeholder="Select role"
              expanded
            >
              <option value="admin">
                Admin
              </option>
              <option value="user">
                User
              </option>
            </b-select>
          </b-field>
          <b-field label="Organisation">
            <b-taginput
              v-model="newUser.organisationIds"
              autocomplete
              :data="organisationList"
              field="name"
              type="is-primary"
              :open-on-focus="true"
            />
          </b-field>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="newUserModalActive = false"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              class="px-6 rounded-8"
              @click="createUser()"
            >
              Create
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>
  </div>
</template>

<script >
import { computed, defineAsyncComponent, onMounted, ref, watch } from '@vue/composition-api';
import { fetchOrganisationsService } from '@/services/user-service/organisationRequests';
import { createUserService } from '@/services/user-service/userRequests';
import { useUserStore } from '@/modules/users/store/userStore';
import { reactiveResetter } from '@/hooks/utils';

//-- composable hooks --//

//-- child components --//
const UserList = defineAsyncComponent(() => import('@/modules/users/components/UserList.vue'));
const BaseModal = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/BaseModal.vue'));
const CardPopup = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/CardPopup.vue'));

//-- user logic --//
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const userStore = useUserStore();
  const [newUser, resetNewUser] = reactiveResetter({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: '',
    organisationIds: []
  });
  const userList = ref(null);
  const newUserModalActive = computed({
    get() {
      return userStore.isNewUserModalActive;
    },
    set(value) {
      userStore.setNewUserModalActive(value);
    }
  });
  watch(() => newUserModalActive.value, () => {
    if (!newUserModalActive.value) {
      resetNewUser();
    }
  });
  const createUser = async () => {
    try {
      if (!newUser.organisationIds.length) {
        return false;
      }
      const organisationIds = [];
      newUser.organisationIds.forEach(item => {
        organisationIds.push(item.id);
      });
      await createUserService({
        email: newUser.email,
        password: newUser.password,
        role: newUser.role,
        personal: {
          first_name: newUser.firstName,
          last_name: newUser.lastName
        },
        organisationIds: organisationIds
      });
      userList.value.fetchUsers();
      newUserModalActive.value = false;
    } catch (err) {
      console.error(err);
    }
  };

  //-- organisation logic --//
  const organisationList = ref([]);
  const fetchOrganisations = async () => {
    try {
      const {
        data: {
          data: organistionData
        }
      } = await fetchOrganisationsService();
      organisationList.value = organistionData;
    } catch (err) {
      console.error(err);
    }
  };
  onMounted(() => {
    fetchOrganisations();
  });
  return {
    newUser,
    userList,
    newUserModalActive,
    createUser,
    organisationList
  };
};
__sfc_main.components = Object.assign({
  UserList,
  BaseModal,
  CardPopup
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.has-light-bg {
  background: $grey-5 !important;
}

.has-insert-shadow {
  -webkit-box-shadow: -10px 9px 15px -6px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 9px 15px -6px rgba(0, 0, 0, 0.1);
}
</style>