// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { ORGANIZATION_RESOURCE_PATH, USER_SERVICE_ENDPOINT } from './userServiceConstants';

const organisationServiceConfig = createHttpServiceConfig(USER_SERVICE_ENDPOINT, ORGANIZATION_RESOURCE_PATH);

//-- request interceptors --//
organisationServiceConfig.addRequestInterceptor(authInterceptor);

export const fetchOrganisationsService = () => organisationServiceConfig.http({
    method: 'GET'
});
