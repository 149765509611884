import { onMounted, reactive, ref, toRefs } from '@vue/composition-api';
import { cloneDeep, debounce } from 'lodash';

/**
 * @callback ReactiveReset
 * @returns {void}
 */
/**
 * returns a reactive object and a function for safely resetting it(by avoiding reactivity loss) to initial state
 * @template O
 * @param {O} initialState
 * @returns {[O, ReactiveReset]}
 */
export const reactiveResetter = (initialState) => {
    let reactiveObj = reactive(cloneDeep(initialState));
    let reset = () => {
        reactiveObj = Object.assign(reactiveObj, cloneDeep(initialState));
    };
    return [reactiveObj, reset];
};

/**
 * @callback RefReset
 * @returns {void}
 */
/**
 * returns a ref object and a function for resetting it to initial state
 ** Note: This should only be used for non-primitive values.
 * @template O
 * @param {O} initialState
 * @returns {[import('@vue/composition-api').Ref<O>, RefReset]}
 */
export const refResetter = (initialState) => {
    if (['string', 'number', 'boolean'].includes(typeof initialState)) {
        console.error('expected non-primitive value, received: ', initialState);
        return;
    }
    let refObj = ref(cloneDeep(initialState));
    let reset = () => {
        refObj.value = cloneDeep(initialState);
    };
    return [refObj, reset];
};

/**
 * Adds drag on scroll functionality to provided html element 
 * @param {import('@vue/composition-api').Ref<HTMLElement>} targetElementRef
 */
export const useDragScroll = (targetElementRef) => {
    if (!targetElementRef) {
        console.error('Expected reactive param of type Ref<HTMLElement>, received: ', targetElementRef);
        return;
    }
    const position = {
        x: 0,
        y: 0,
        scrollLeft: 0,
        scrollTop: 0
    };
    const isDragging = ref(false);
    const elementScrollParams = reactive({
        scrollLeft: 0,
        scrollTop: 0
    });
    
    //-- mouse event handlers --//
    /**
     * @param {MouseEvent} event
     */
    const handleMouseDown = (event) => {
        position.scrollTop = targetElementRef.value.scrollTop;
        position.scrollLeft = targetElementRef.value.scrollLeft;
        position.x = event.clientX;
        position.y = event.clientY;
        targetElementRef.value.style.userSelect = 'none';
        isDragging.value = true;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };
    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        targetElementRef.value.style.removeProperty('user-select');
        isDragging.value = false;
    };
    /**
     * @param {MouseEvent} event 
     */
    const handleMouseMove = (event) => {
        // compute distance travelled by mouse
        const distanceX = event.clientX - position.x;
        const distanceY = event.clientY - position.y;
        const computedScrollTop = position.scrollTop - distanceY;
        const computedScrollLeft = position.scrollLeft - distanceX;
        // update element scroll values
        targetElementRef.value.scrollTop = computedScrollTop;
        targetElementRef.value.scrollLeft = computedScrollLeft;
        debounceScrollUpdate();
    };

    //-- reactive scroll params update logic --//
    // debounce the update reactive scroll values
    const debounceScrollUpdate = debounce(() => {
        elementScrollParams.scrollLeft = targetElementRef.value.scrollLeft;
        elementScrollParams.scrollTop = targetElementRef.value.scrollTop;
    }, 500);
    onMounted(() => {
        targetElementRef.value.addEventListener('mousedown', handleMouseDown);
        targetElementRef.value.addEventListener('scroll', debounceScrollUpdate);
    });

    // utilities
    /**
     * @param {import('./types/utils').IUpdateScrollOptions} scrollOptions 
     */
    const updateScroll = (scrollOptions) => {
        if (targetElementRef.value.firstElementChild) {
            const maxHorizontalScroll = targetElementRef.value.firstElementChild.clientWidth - targetElementRef.value.clientWidth;
            const maxVerticalScroll = targetElementRef.value.firstElementChild.clientHeight - targetElementRef.value.clientHeight;
            handleScroll(scrollOptions, maxHorizontalScroll, 'horizontalScroll');
            handleScroll(scrollOptions, maxVerticalScroll, 'verticalScroll');
        }
    };
    /**
     * @param {import('./types/utils').IUpdateScrollOptions} scrollOptions
     * @param {number} maxScollLimit
     * @param {import('./types/utils').ScrollTypes} scrollType
     */
    const handleScroll = (scrollOptions, maxScollLimit, scrollType) => {
        if (scrollOptions[scrollType]) {
            if (scrollOptions[scrollType] === 'center') {
                targetElementRef.value.scrollLeft = maxScollLimit / 2;
            } else if (scrollOptions[scrollType] === 'right') {
                targetElementRef.value.scrollLeft = maxScollLimit;
            } else {
                targetElementRef.value.scrollLeft = scrollOptions[scrollType];
            }
        }
    };

    return {
        ...toRefs(elementScrollParams),
        isDragging,
        updateScroll
    };
};
